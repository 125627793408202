import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasePanelWithHeader',{attrs:{"headerText":"Potential Duplicates"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(VRow,[_c(VCol,{directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length > 0),expression:"selected.length > 0"}],staticClass:"ml-auto",attrs:{"cols":"12","sm":"auto"}},[_c(VBtn,{staticClass:"ma-2 mb-5",attrs:{"small":"","color":"primary","disabled":_vm.activateDeactivateRunning},on:{"click":_vm.activateSelected}},[_vm._v(" Activate Selected ")]),_c(VBtn,{staticClass:"ma-2 mb-5",attrs:{"small":"","color":"primary","disabled":_vm.activateDeactivateRunning},on:{"click":_vm.deactivateSelected}},[_vm._v(" Deactivate Selected ")])],1),_c(VSpacer),_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"12","sm":"auto"}},[_c(VTextField,{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search","clearable":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c(VCol,{attrs:{"sm":"2"}},[_c('excelExport',{attrs:{"data":_vm.sortedExceptions,"exportFields":_vm.excelFields,"worksheet":"Sheet1","name":"DuplicateExceptionExport.xlsx"}},[_vm._v("Export")])],1)],1),_c(VDataTable,{staticClass:"pt-4",attrs:{"group-by":"RowNumber","multi-sort":"","search":_vm.searchText,"sort-by":_vm.sortBy,"headers":_vm.filteredHeaders,"items":_vm.mappedExceptions,"loading":_vm.loading,"expanded":_vm.expanded,"show-expand":"","item-key":"ID","footer-props":{
        itemsPerPageOptions: [5, 10, 15, 25, 50, 100, 500, 1000],
      },"show-select":"","dense":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
      var items = ref.items;
      var isOpen = ref.isOpen;
      var toggle = ref.toggle;
      var headers = ref.headers;
return [_c('th',{attrs:{"colspan":headers.length}},[_c(VIcon,{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")]),_vm._v(" Row Number: "+_vm._s(items[0].RowNumber)+", Duplicate Count: "+_vm._s(items[0].DuplicateCount)+", Invoice Date: "+_vm._s(_vm._f("dateformat")(items[0].InvoiceDate))+", Document Number: "+_vm._s(items[0].DocumentNumber)+", Taxable Amount: "+_vm._s(_vm._f("moneyFormat")(items[0].TaxableAmount))+", Vendor Customer: "+_vm._s(items[0].VendorCustomer)+" ")],1)]}},{key:"expanded-item",fn:function(ref){
      var item = ref.item;
      var headers = ref.headers;
return [_c('td',{staticClass:"pa-2",attrs:{"colspan":headers.length}},[_c('div',[_vm._v(" Row Number: "+_vm._s(item.RowNumber)+", Duplicate Count: "+_vm._s(item.DuplicateCount)+", Invoice Date: "+_vm._s(_vm._f("dateformat")(item.InvoiceDate))+", Document Number: "+_vm._s(item.DocumentNumber)+", Taxable Amount: "+_vm._s(_vm._f("moneyFormat")(item.TaxableAmount))+", Vendor Customer: "+_vm._s(item.VendorCustomer))]),_c('div',[_vm._v(" Issue: "+_vm._s(_vm.getIssue(item.IssueID)))]),_c('div',[_vm._v(" Description: "+_vm._s(item.Description))]),_c('div',[_vm._v(" Created By: "+_vm._s(_vm.getExceptionUser(item.CreatedByID)))]),_c('div',[_vm._v(" Modified By: "+_vm._s(_vm.getExceptionUser(item.ModifiedByID)))])])]}},{key:"item.edit",fn:function(ref){
      var item = ref.item;
return [_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-pencil"},on:{"click":function($event){return _vm.$refs.exciseExceptionsForm.editExciseExceptions(item)}}},[_vm._v("Edit Excise Exceptions ")])]}},{key:"item.delete",fn:function(ref){
      var item = ref.item;
return [_c('BaseTooltipButton',{attrs:{"small":"","iconColor":"primary","icon":"mdi-delete"},on:{"click":function($event){return _vm.$refs.exciseExceptionsDelete.deleteConfirm(item)}}},[_vm._v("Delete Excise Exceptions ")])]}},{key:"item.TaxableAmount",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moneyFormat")(item.TaxableAmount)))])]}},{key:"item.TaxPaid",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moneyFormat")(item.TaxPaid)))])]}},{key:"item.TaxRate",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatPercent(item.TaxRate)))])]}},{key:"item.TaxDue",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moneyFormat")(item.TaxDue)))])]}},{key:"item.StratumID",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:!item.stratum.IsActive ? 'grey--text text--lighten-1' : ''},[_vm._v(_vm._s(item.stratum.Description)),(item.stratum.StratumNumber)?_c('span',[_vm._v(" ("+_vm._s(item.stratum.StratumNumber)+")")]):_vm._e()])]}},{key:"item.IssueID",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("snippet")(_vm.getIssue(item.IssueID),_vm.snippetSize)))])]}},{key:"item.Description",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("snippet")(item.Description,_vm.snippetSize)))])]}},{key:"item.TransactionTypeID",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getTransactionType(item.TransactionTypeID)))])]}},{key:"item.TaxTypeID",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getTaxType(item.TaxTypeID)))])]}},{key:"item.DeliveredToCountyID",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getCounty(item.DeliveredToCountyID)))])]}},{key:"item.DeliveredToCityID",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getCity(item.DeliveredToCityID)))])]}},{key:"item.SpecialDistrictID",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getSpecialDistrict(item.SpecialDistrictID)))])]}},{key:"item.CreatedByID",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getExceptionUser(item.CreatedByID)))])]}},{key:"item.ModifiedByID",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getExceptionUser(item.ModifiedByID)))])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('BaseDeleteConfirm',{ref:"exciseExceptionsDelete",attrs:{"delete":_vm.deleteExciseExceptions},on:{"refresh":_vm.loadSelectedProjectExciseExceptionsDuplicates}},[_vm._v(" Are you sure you want to delete this excise exceptions? ")]),_c('ExciseExceptionsDialogForm',{ref:"exciseExceptionsForm",attrs:{"vendorCustomer":_vm.vendorCustomer,"references":_vm.references,"stratum":_vm.stratum,"projectDetailsId":_vm.selectedProject.ProjectDetailsID},on:{"refresh":_vm.loadSelectedProjectExciseExceptionsDuplicates}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }