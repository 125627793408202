<template>
  <BasePanelWithHeader headerText="Potential Duplicates">
    <template v-slot:content>
      <!-- <ExciseExceptionsParams
        :showSort="false"
        :headers="filteredHeaders"
      ></ExciseExceptionsParams> -->
      <v-row>
        <!-- <v-col cols="12" sm="auto">
          <v-switch v-model="showInactive" label="Show Inactive"></v-switch>
        </v-col> -->
        <v-col v-show="selected.length > 0" class="ml-auto" cols="12" sm="auto">
          <v-btn
            class="ma-2 mb-5"
            small
            color="primary"
            :disabled="activateDeactivateRunning"
            @click="activateSelected"
          >
            Activate Selected
          </v-btn>
          <v-btn
            class="ma-2 mb-5"
            small
            color="primary"
            :disabled="activateDeactivateRunning"
            @click="deactivateSelected"
          >
            Deactivate Selected
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="ml-auto" cols="12" sm="auto">
          <v-text-field
            v-model="searchText"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col sm="2">
          <excelExport
            :data="sortedExceptions"
            :exportFields="excelFields"
            worksheet="Sheet1"
            :name="`DuplicateExceptionExport.xlsx`"
            >Export</excelExport
          >
        </v-col>
      </v-row>
      <!-- show-group-by -->
      <v-data-table
        v-model="selected"
        group-by="RowNumber"
        multi-sort
        :search="searchText"
        :sort-by.sync="sortBy"
        :headers="filteredHeaders"
        :items="mappedExceptions"
        :loading="loading"
        :expanded.sync="expanded"
        show-expand
        item-key="ID"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 15, 25, 50, 100, 500, 1000],
        }"
        show-select
        class="pt-4"
        dense
      >
        <template v-slot:group.header="{items, isOpen, toggle, headers}">
          <th :colspan="headers.length">
            <v-icon @click="toggle"
              >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
            </v-icon>
            Row Number: {{ items[0].RowNumber }}, Duplicate Count:
            {{ items[0].DuplicateCount }}, Invoice Date:
            {{ items[0].InvoiceDate | dateformat }}, Document Number:
            {{ items[0].DocumentNumber }}, Taxable Amount:
            {{ items[0].TaxableAmount | moneyFormat }}, Vendor Customer:
            {{ items[0].VendorCustomer }}
          </th>
        </template>
        <!-- <template v-slot:group.header.data-table-expand="{on, props}">
          <span>Expand</span>
        </template> -->
        <template v-slot:expanded-item="{item, headers}">
          <td :colspan="headers.length" class="pa-2">
            <div>
              Row Number: {{ item.RowNumber }}, Duplicate Count:
              {{ item.DuplicateCount }}, Invoice Date:
              {{ item.InvoiceDate | dateformat }}, Document Number:
              {{ item.DocumentNumber }}, Taxable Amount:
              {{ item.TaxableAmount | moneyFormat }}, Vendor Customer:
              {{ item.VendorCustomer }}</div
            >
            <div> Issue: {{ getIssue(item.IssueID) }}</div>
            <div> Description: {{ item.Description }}</div>
            <div> Created By: {{ getExceptionUser(item.CreatedByID) }}</div>
            <div> Modified By: {{ getExceptionUser(item.ModifiedByID) }}</div>
          </td>
        </template>
        <template v-slot:item.edit="{item}">
          <BaseTooltipButton
            small
            @click="$refs.exciseExceptionsForm.editExciseExceptions(item)"
            iconColor="primary"
            icon="mdi-pencil"
            >Edit Excise Exceptions
          </BaseTooltipButton>
        </template>
        <template v-slot:item.delete="{item}">
          <BaseTooltipButton
            small
            @click="$refs.exciseExceptionsDelete.deleteConfirm(item)"
            iconColor="primary"
            icon="mdi-delete"
            >Delete Excise Exceptions
          </BaseTooltipButton>
        </template>
        <template v-slot:item.TaxableAmount="{item}">
          <span>{{ item.TaxableAmount | moneyFormat }}</span>
        </template>
        <template v-slot:item.TaxPaid="{item}">
          <span>{{ item.TaxPaid | moneyFormat }}</span>
        </template>
        <template v-slot:item.TaxRate="{item}">
          <span>{{ formatPercent(item.TaxRate) }}</span>
        </template>
        <template v-slot:item.TaxDue="{item}">
          <span>{{ item.TaxDue | moneyFormat }}</span>
        </template>
        <template v-slot:item.StratumID="{item}">
          <span
            :class="!item.stratum.IsActive ? 'grey--text text--lighten-1' : ''"
            >{{ item.stratum.Description
            }}<span v-if="item.stratum.StratumNumber"
              >&nbsp;({{ item.stratum.StratumNumber }})</span
            ></span
          >
        </template>
        <template v-slot:item.IssueID="{item}">
          <span>{{ getIssue(item.IssueID) | snippet(snippetSize) }}</span>
        </template>
        <template v-slot:item.Description="{item}">
          <span>{{ item.Description | snippet(snippetSize) }}</span>
        </template>
        <template v-slot:item.TransactionTypeID="{item}">
          <span>{{ getTransactionType(item.TransactionTypeID) }}</span>
        </template>
        <template v-slot:item.TaxTypeID="{item}">
          <span>{{ getTaxType(item.TaxTypeID) }}</span>
        </template>
        <template v-slot:item.DeliveredToCountyID="{item}">
          <span>{{ getCounty(item.DeliveredToCountyID) }}</span>
        </template>
        <template v-slot:item.DeliveredToCityID="{item}">
          <span>{{ getCity(item.DeliveredToCityID) }}</span>
        </template>
        <template v-slot:item.SpecialDistrictID="{item}">
          <span>{{ getSpecialDistrict(item.SpecialDistrictID) }}</span>
        </template>
        <template v-slot:item.CreatedByID="{item}">
          <span>{{ getExceptionUser(item.CreatedByID) }}</span>
        </template>
        <template v-slot:item.ModifiedByID="{item}">
          <span>{{ getExceptionUser(item.ModifiedByID) }}</span>
        </template>
      </v-data-table>
      <BaseDeleteConfirm
        ref="exciseExceptionsDelete"
        :delete="deleteExciseExceptions"
        @refresh="loadSelectedProjectExciseExceptionsDuplicates"
      >
        Are you sure you want to delete this excise exceptions?
      </BaseDeleteConfirm>
      <ExciseExceptionsDialogForm
        ref="exciseExceptionsForm"
        @refresh="loadSelectedProjectExciseExceptionsDuplicates"
        :vendorCustomer="vendorCustomer"
        :references="references"
        :stratum="stratum"
        :projectDetailsId="selectedProject.ProjectDetailsID"
      >
      </ExciseExceptionsDialogForm>
    </template>
  </BasePanelWithHeader>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import ExciseExceptionsDialogForm from '@components/form/add-edit/ExciseExceptionsDialog'
import ExciseExceptionsParams from '@components/search/ExciseExceptionsParams'
import excelExport from '@components/excelExport'
export default {
  components: {
    ExciseExceptionsDialogForm,
    ExciseExceptionsParams,
    excelExport,
  },
  data: () => ({
    searchText: '',
    showInactive: false,
    selected: [],
    searchText: '',
    sortBy: 'RowNumber',
    groupBy: 'RowNumber',
    expanded: [],
  }),
  props: {
    projectId: {
      type: Number,
    },
  },
  created() {
    this.loadLuCounty()
    this.loadLuCity()
    this.loadLuSpecialDistrict()
    this.loadLuExciseIssues()
    this.loadLuExciseTransactionType()
    this.loadLuExciseTaxTypes()
    if (this.$route.params.projectId) {
      this.searchProjectID = parseInt(this.$route.params.projectId)
      this.loadSelectedProject(this.$route.params.projectId).then(() => {
        this.loadSelectedProjectExciseExceptionsDuplicateCount()
        this.loadSelectedProjectExciseExceptionsDuplicates()
        this.loadSelectedProjectExciseExceptionReferencesObject()
        this.loadSelectedProjectExciseExceptionVendorCustomerObject()
        this.loadSelectedProjectExciseStratum()
        this.loadSelectedProjectExciseExceptionUsers()
        this.getRelatedOptions()
      })
    }
  },
  computed: {
    vuetify() {
      return this.$vuetify
    },
    ...get('project', ['selectedProject']),
    ...sync('exciseExceptions', [
      'searchProjectID',
      'searchTotal',
      'searchPage',
      'searchSortBy',
      'searchDescending',
      'searchRowsPerPage',
      'activateDeactivateRunning',
    ]),
    exciseExceptions: get('project/selectedProjectExciseExceptionsDuplicates'),
    loading: get('project/exciseExceptionsDuplicatesLoading'),
    vendorCustomer: get(
      'project/selectedProjectExciseExceptionVendorCustomerObject'
    ),
    references: get('project/selectedProjectExciseExceptionReferencesObject'),
    stratum: get('project/selectedProjectExciseStratum'),
    exceptionUsers: get('project/selectedProjectExciseExceptionUsers'),
    sortedExceptions() {
      let exceptions = this.exciseExceptions.filter((i) => true)
      return exceptions.sort((a, b) => {
        return a.RowNumber - b.RowNumber
      })
    },
    snippetSize() {
      var width = this.$vuetify.breakpoint.width
      if (width < 1730) {
        return 10
      } else if (width < 1780) {
        return 16
      } else if (width < 1880) {
        return 20
      } else if (width < 1920) {
        return 24
      } else if (width >= 1920) {
        return 26
      }
      return 10
    },
    filteredExciseExceptions() {
      var list = this.exciseExceptions.filter((i) => true)
      if (!this.showInactive) {
        list = list.filter((i) => i.IsActive)
      }
      return list
    },
    excelFields() {
      var fullHeaderList = [
        'RowNumber',
        'DuplicateCount',
        'IsActive',
        'StratumID',
        'TransactionTypeID',
        'TaxTypeID',
        'InvoiceDate',
        'VendorCustomer',
        'DocumentNumber',
        'IssueID',
        'Description',
        'TaxableAmount',
        'TaxPaid',
        'TaxRate',
        'TaxDue',
        'DeliveredToCountyID',
        'DeliveredToCityID',
        'SpecialDistrictID',
        'Reference',
        'CreatedByID',
        'ModifiedByID',
        'ID',
      ]
      let fields = {}
      fullHeaderList.forEach((i) => {
        var text = i.replace(/([A-Z][a-z])/g, ' $1').replace(/(\d)/g, ' $1')
        fields[text] = i
      })
      return fields
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Row Number',
          align: 'center',
          sortable: true,
          value: 'RowNumber',
          visible: true,
        },
        {
          text: 'Duplicate Count',
          align: 'center',
          sortable: true,
          value: 'DuplicateCount',
          visible: false,
        },
        {
          text: 'Invoice Date',
          align: 'center',
          sortable: true,
          value: 'InvoiceDate',
          visible: false,
        },
        {
          text: 'Document Number',
          align: 'center',
          sortable: true,
          value: 'DocumentNumber',
          visible: false,
        },
        {
          text: 'Taxable Amount',
          align: 'center',
          sortable: true,
          value: 'TaxableAmount',
          visible: false,
        },
        {
          text: 'Vendor Customer',
          align: 'center',
          sortable: true,
          value: 'VendorCustomer',
          visible: false,
        },
        {
          text: 'Issue',
          align: 'center',
          sortable: true,
          value: 'IssueID',
          visible: true,
        },
        {
          text: 'Delivered To County',
          align: 'center',
          sortable: true,
          value: 'DeliveredToCountyID',
          visible: true,
        },
        {
          text: 'Stratum',
          align: 'center',
          sortable: true,
          value: 'StratumID',
          visible: true,
        },
        {
          text: 'Transaction Type',
          align: 'center',
          sortable: true,
          value: 'TransactionTypeID',
          visible: true,
        },
        {
          text: 'Tax Type',
          align: 'center',
          sortable: true,
          value: 'TaxTypeID',
          visible: true,
        },
        {
          text: 'Delivered To City',
          align: 'center',
          sortable: true,
          value: 'DeliveredToCityID',
          visible: true,
        },
        {
          text: 'Special District',
          align: 'center',
          sortable: true,
          value: 'SpecialDistrictID',
          visible: true,
        },
        {
          text: 'Description',
          align: 'center',
          sortable: true,
          value: 'Description',
          visible: true,
        },
        {
          text: 'Tax Paid',
          align: 'center',
          sortable: true,
          value: 'TaxPaid',
          visible: true,
        },
        {
          text: 'Tax Rate',
          align: 'center',
          sortable: true,
          value: 'TaxRate',
          visible: false,
        },
        {
          text: 'Tax Due',
          align: 'center',
          sortable: true,
          value: 'TaxDue',
          visible: false,
        },
        {
          text: 'Reference',
          align: 'center',
          sortable: true,
          value: 'Reference',
          visible: true,
        },
        {
          text: 'Is Active',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: false,
        },
        {
          text: 'Created By',
          align: 'center',
          sortable: true,
          value: 'CreatedByID',
          visible: false,
        },
        {
          text: 'Modified By',
          align: 'center',
          sortable: true,
          value: 'ModifiedByID',
          visible: false,
        },
        {
          text: 'ID',
          align: 'center',
          sortable: true,
          value: 'ID',
          visible: true,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    luCounty: get('luCounty/luCounty'),
    luCity: get('luCity/luCity'),
    luSpecialDistrict: get('luSpecialDistrict/luSpecialDistrict'),
    luExciseIssues: get('luExciseIssues/luExciseIssues'),
    luExciseTransactionType: get(
      'luExciseTransactionType/luExciseTransactionType'
    ),
    luExciseTaxTypes: get('luExciseTaxTypes/luExciseTaxTypes'),
    availableTaxTypes() {
      var projectDetailsID = this.selectedProject.ProjectDetailsID
      return this.luExciseTaxTypes.filter((item) => {
        return item.IsActive && item.ProjectDetailID == projectDetailsID
      })
    },
    mappedExceptions() {
      return this.exciseExceptions.map((item) => {
        return {
          ...item,
          stratum: this.getStratum(item.StratumID),
        }
      })
    },
  },
  methods: {
    loadLuCounty: call('luCounty/loadLuCounty'),
    loadLuCity: call('luCity/loadLuCity'),
    loadLuSpecialDistrict: call('luSpecialDistrict/loadLuSpecialDistrict'),
    loadLuExciseIssues: call('luExciseIssues/loadLuExciseIssues'),
    loadLuExciseTransactionType: call(
      'luExciseTransactionType/loadLuExciseTransactionType'
    ),
    loadLuExciseTaxTypes: call('luExciseTaxTypes/loadLuExciseTaxTypes'),
    ...call('project', [
      'loadSelectedProject',
      'loadSelectedProjectExciseStratum',
      'loadSelectedProjectExciseExceptionReferencesObject',
      'loadSelectedProjectExciseExceptionVendorCustomerObject',
      'loadSelectedProjectExciseExceptionUsers',
      'loadSelectedProjectExciseExceptionsDuplicateCount',
      'loadSelectedProjectExciseExceptionsDuplicates',
    ]),
    ...call('exciseExceptions', [
      'deleteExciseExceptions',
      'searchExciseExceptions',
      'activateDeactivateSelectedExciseExceptions',
      'getRelatedOptions',
    ]),
    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item]
    },
    getStratum(id) {
      if (id > 0) {
        let match = this.stratum.find((item) => item.ID == id)
        return match ? match : {}
      }
    },
    getIssue(id) {
      if (id > 0) {
        let match = this.luExciseIssues.find((item) => item.ID == id)
        return match ? match.Description : ''
      }
    },
    getTransactionType(id) {
      if (id > 0) {
        let match = this.luExciseTransactionType.find((item) => item.ID == id)
        return match ? match.Description : ''
      }
    },
    getTaxType(id) {
      if (id > 0) {
        let match = this.luExciseTaxTypes.find((item) => item.ID == id)
        return match ? match.TaxType : ''
      }
    },
    getCounty(id) {
      if (id > 0) {
        let match = this.luCounty.find((item) => item.ID == id)
        return match ? match.Name : ''
      }
    },
    getCity(id) {
      if (id > 0) {
        let match = this.luCity.find((item) => item.ID == id)
        return match ? match.City : ''
      }
    },
    getSpecialDistrict(id) {
      if (id > 0) {
        let match = this.luSpecialDistrict.find((item) => item.ID == id)
        return match ? match.SpecialDistrict : ''
      }
    },
    getExceptionUser(id) {
      if (id > 0) {
        let match = this.exceptionUsers.find((item) => item.ID == id)
        if (match) {
          if (match.FirstName && match.LastName) {
            return match.FirstName + ' ' + match.LastName
          } else {
            return match.eMail
          }
        } else {
          return ''
        }
      }
    },
    formatPercent(val) {
      if (val != null && val != undefined) {
        return parseFloat((val * 100).toFixed(2)) + '%'
      }
    },
    activateSelected() {
      let ids = this.selected.map((i) => i.ID)
      this.activateDeactivateSelectedExciseExceptions({ids, active: true})
    },
    deactivateSelected() {
      let ids = this.selected.map((i) => i.ID)
      // console.devlog(ids)
      this.activateDeactivateSelectedExciseExceptions({ids, active: false})
    },
  },
}
</script>
<style></style>
